export const de = {
	childTest: `Willkommen zum Test`,
	welcomeToTest: `Willkommen zum Test`,
	literateChildTest: `Dysmate test`,
	youAreReady: `Du bist bereit`,
	ready_youth: `Start`,
	ready: `Los`,
	chooseRole: `Rolle auswählen`,
	chooseRoleHelp: `Wählen Sie bitte, ob dieses Gerät der Testleiterin/dem Testleiter oder der Schülerin/dem Schüler zugeordnet ist`,
	chooseLanguage: `Sprache auswählen`,
	lang_no: `Norwegisch (Bokmål)`,
	lang_nn: `Norwegisch (Nynorsk)`,
	lang_se: `Schwedisch`,
	lang_de: `Deutsch`,
	lang_nl: `Niederländisch`,
	lang_en: `Englisch`,
	blue: `blau`,
	green: `grün`,
	pink: `rosa`,
	yellow: `gelb`,
	red: `rot`,
	violet: `lila`,
	orange: `orange`,
	gray: `grau`,
	grey: `grau`,
	brown: `braun`,
	dog: `hund`,
	shoe: `schuh`,
	tree: `baum`,
	door: `tür`,
	moon: `mond`,
	unknown: `Unbekannt`,
	testadministrator: `Testleiterin/Testleiter`,
	testcandidate: `Schülerin/Schüler`,
	testkey: `Testschlüssel`,
	emailaddress: `E-Mail-Addresse`,
	starttest: `Test starten`,
	candidateInstructions: `Trage den Code ein, den Dir Deine Lehrerin oder Dein Lehrer vorliest oder zeigt. Tippe “Zum Test”.`,
	gototest: `Zum Test`,
	jumptotest: `Zu dieser Aufgabe wechseln`,
	roomkey: `Testschlüssel: {0}`,
	waitforadminstart: `Warte, bis der Testleiter den Test startet`,
	done: `Fertig`,
	name: `Name`,
	sound: `Klang`,
	helpBox1: `Auf die Schülerin/den Schüler warten. Bitte den Code, der Ihnen angezeigt wird, laut vorlesen und ggf. der Schülerin/dem Schüler zeigen. Dieser wird zum Starten des Follow-Up-Tests benötigt.`,
	helpBoxList1: `Öffnen Sie die Seite {0} auf einem anderen Gerät.`,
	helpBoxList2: `Wählen Sie „Schülerin/Schüler“ `,
	helpBoxList3: `Testschlüssel eingeben ({0})`,
	helpBoxList4: `Klicken Sie auf „Zum Test“`,
	helpBoxList5: `Wenn Sie bereit sind, klicken Sie unten auf dieser Seite auf „Test starten“.`,
	infoAboutCandidate: `Informationen zum Testkandidaten:`,
	generalInformation: `Allgemeine Informationen`,
	notaBene: `NB!`,
	candidateReminder: `{0} Denken Sie daran, dass die Informationen des Testkandidat ausgefüllt werden müssen, nicht Ihre`,
	firstName: `Vorname`,
	lastName: `Nachname`,
	birthDate: `Geburtsdatum`,
	gender: `Geschlecht`,
	pickOne: `Wähle ein`,
	boy: `männlich`,
	girl: `weiblich`,
	unspecified: `divers`,
	education: `Ausbildung`,
	municipality: `Gemeinde`,
	county: `County`,
	school: `Schule`,
	educationLevel: `Bildungsniveau:`,
	kindergarten: `Kindergarten und 1.Klasse Grundschule`,
	primarySchool: `Grundschule (2.-5.Klasse)`,
	juniorHighSchool: `Mittelschule (6.-10.Klasse)`,
	highSchool: `Weiterführende Schule`,
	university: `Universität`,
	other: `Andere`,
	pickYear: `Jahr auswählen`,
	pickClass: `Klasse auswählen`,
	pickLevel: `Stufe auswählen`,
	pick: `Wählen`,
	lastYearBeforeSchool: `Letztes Jahr vor der Einschulung`,
	class: `Klassenstufe`,
	classNum: `{0}. Klasse`,
	bilinguagualHomeQuestion: `Sind Sie in einem ein- oder zweisprachigen Elternhaus aufgewachsen?`,
	unilingual: `Einsprachig`,
	bilingual: `Zweisprachig`,
	multilingual: `Mehrsprachig`,
	iConsent: `Ich bestätige`,
	consentText: `Als Testadministrator bestätigen Sie, dass Sie die erforderliche Zustimmung des Testkandidaten zur Erhebung personenbezogener Daten haben und dass diese wie beschrieben verwendet werden`,
	literatePrivacyPolicy: `Literate Datenschutzerklärung`,
	practiceTasks: `Beispielen`,
	startPracticeTasks: `Übungsaufgaben beginnen`,
	listenToInstructions: `Hör auf die Anleitung.`,
	waitingForCandidate: `Warten Sie auf die Schülerin/den Schüler`,
	logIn: `Anmelden`,
	loginWithBankId: `Melden Sie sich mit BankID an, indem Sie auf eine der folgenden Schaltflächen klicken`,
	thisIsForTestAdmins: `Dies ist für Testadministratoren (d. h. zertifizierte Benutzer mit einem Abonnement)`,
	pauseBeforeNextTest: `Du hast es geschafft! Die Aufgabe zum {0} ist nun beendet.`,
	max2Listens: `Maximal zwei Plays pro Audiotrack`,
	timeUsed: `Zeitaufwand: {0}`,
	start_youth: `Start`,
	start: `Los`,
	pause: `Pause`,
	stop: `Stoppen`,
	reset: `Zurücksetzen`,
	testIsFinished: `Die Aufgabe zum Laute vertauschen ist nun beendet!`,
	TesFinished_Instruction_ONCANDIATESIDE: `Geschafft! Du hast alle 4 Aufgaben beendet. Der Test ist nun vorbei!`,
	TestCompleteSalutation:`Glückwunsch! Du hast alle 5 Aufgaben gemeistert.`,
	testFinishedInstruction: `Die Antworten wurden alle gespeichert. Sie können die Anwendung nun schließen oder einen weiteren Testdurchlauf mit einer weiteren Schülerin oder einem weiteren Schüler beginnen. Vielen Dank!	`,
	closeTest: `Test schließen`,
	hotkeyDescription: `Markieren Sie richtige und falsche Antworten, indem Sie auf die Schaltflächen unten klicken oder die folgenden Tastenkombinationen verwenden:`,
	hotkeyDescriptionChild:``,
	hotkeyCorrect: `A: Richtig`,
	hotkeyWrong: `S: Falsch`,
	hotkeyUnanswered:`D: Unbeantwortet`,
	hotkeyDescSpoonerism:`Markieren Sie richtige und falsche Antworten, indem Sie auf die Schaltflächen unten klicken.`,
	hotkeyCorrectSpoonerism:`Richtig`,
	hotkeyWrongSpoonerism:`Falsch`,
	hotkeyButtonCorrect: `Richtig (A)`,
	hotkeyButtonWrong: `Falsch (S)`,
	hotkeyButtonUnanswered: `Unbeantwortet (D)`,
	taskNum: `Aufgabe: {0}`,
	taskNumOf: `Aufgabe: {0} von {1}`,
	correctAnswer: `korrekte Antwort: {0}`,
	nextTest: `Start`,
	nextTestChild:`Zweiter Teil`,
	nextSubtest:`Nächste Aufgabe`,
	correctAnswerGiven: `Richtige Antwort gegeben`,
	expectedAnswer: `Erwartete Antwort: {0}`,
	expectedAnswerChild:`Richtige Lösung: {0}`,
	praiseCandidate:`Wenn die Antwort richtig war: Sehr gut, das war richtig!`,
	correctCandidate:`Wenn die Antwort falsch war: Das war nicht ganz richtig. {0}`,
	remindChild:`Bevor Sie zum dritten Beispiel übergehen, erinnern Sie bitte das Kind daran, mit dem Sprechen auf den Piepton zu warten.`,
	expectedAnswerDetailed:` 
	Wenn die Antwort falsch war: Das war nicht ganz richtig. Die {0} „{1}“ rückwärts gesprochen ist: „{2}“.`,
	
	clickStartWhenReady: `Klicken Sie auf „Test starten“, wenn Sie und die Schülerin oder der Schüler bereit sind.`,
	clickStartWhenReadyEmpty: ``,
	workingMemoryClickStart: `Klicken Sie auf „Test starten“, wenn Sie und die Schülerin oder der Schüler bereit sind.`,
	testDone: `Der Test ist nun beendet.`,
	testDoneChild: `Die Aufgabe zum {0} ist nun beendet.`,
	makeSureChildReady:`Stellen Sie sicher, dass die Schülerin/der Schüler eine Pause macht und fahren Sie erst mit dem zweiten Teil der Aufgabe zum Arbeitsgedächtnis fort, wenn Sie den Eindruck haben, dass die Schülerin/der Schüler bereit dazu ist. Klicken Sie auf „{0}“, wenn Sie und Ihre Schülerin oder Ihr Schüler bereit sind.`,
	ensureReadiness:`Stellen Sie sicher, dass die Schülerin/der Schüler eine Pause macht und fahren Sie erst mit der nächsten Aufgabe fort, wenn Sie den Eindruck haben, dass die Schülerin/der Schüler bereit dazu ist. Klicken Sie auf „{0}“, wenn Sie und Ihre Schülerin oder Ihr Schüler bereit sind.`,
	testName_WorkingMemory: `Zahlen und Wörter merken`,
	testName_WorkingMemoryNumbers: `Zahlen merken`,
	testName_WorkingMemoryWords: `Wörter merken`,
	testName_RapidNaming: `Bilder benennen`,
	testName_PhonemeDeletion: 'Laute weglassen',
	testName_OneMinute: `Wörter vorlesen`,
	testName_Spoonerism: `Laute vertauschen`,
	testName_TestComplete: `Der Test ist abgeschlossen`,
	rn_testname: `Bilder benennen`,
	rn_admin1: `Dieser Test misst, wie schnell die Schülerin/der Schüler Bilder in verschiedenen Farben benennen kann. `,
	rn_admin2: `Die Schülerin/der Schüler sieht in dieser Aufgabe Bilder, die in verschiedenen Farben erscheinen. Diese Bilder und deren Farben sollen so schnell und genau wie möglich benannt werden. `,
	rn_admin3: `Zuerst werden die Farben gezeigt und benannt, bevor die Schülerin oder der Schüler die Farben so schnell wie möglich benennen soll.
	Ihre Aufgabe besteht darin, zu prüfen, ob die Antwort der Schülerin oder des Schülers richtig ist. Wenn die Antwort richtig ist, fahren Sie mit der Einführung der Bilder fort. Wenn die Antwort falsch ist, korrigieren Sie die Schülerin oder den Schüler, indem Sie die richtigen Namen der Farben nennen.`,
	rn_adjustIfNotCorrect: `Jetzt entweder mit der Einführung der Bilder fortfahren oder die Schülerin oder den Schüler korrigieren. `,
	rn_colors: `rot – blau – grün – gelb – braun `,
	rn_objects: `Hund – Schuh – Baum – Tür – Mond`,
	rn_objectsWithColors: `roter Hund – blauer Schuh – grüner Baum – gelbe Tür – brauner Mond`,
	rn_correctColors: `Die Farben wurden korrekt benannt`,
	rn_correctItems: `Die Bilder wurden korrekt benannt`,
	rn_admin4: ` Jetzt werden die Bilder gezeigt und benannt, bevor die Schülerin oder der Schüler die Bilder so schnell wie möglich benennen soll. Ihre Aufgabe besteht darin, zu prüfen, ob die Antwort der Schülerin oder des Schülers richtig ist. Wenn die Antwort richtig ist, fahren Sie mit dem Beispiel fort. Wenn die Antwort falsch ist, korrigieren Sie die Schülerin oder den Schüler, indem Sie die richtigen Namen der Bilder nennen.`,
	rn_admin5: `Im Beispiel werden die Objekte mit den Farben kombiniert. Sie werden zunächst gezeigt und benannt, bevor die Schülerin oder der Schüler die Bilder und die Farben so schnell wie möglich benennen soll.`,
	rn_admin6: `Ihre Aufgabe besteht darin, zu prüfen, ob die Antwort der Schülerin oder des Schülers richtig ist. Wenn die Antwort richtig ist, kann die eigentliche Aufgabe beginnen. Wenn die Antwort falsch ist, korrigieren Sie die Schülerin oder den Schüler, indem Sie die richtigen Namen der farbigen Bilder nennen.`,
	rn_admin10: `Zuerst werden die Farben gezeigt und benannt, bevor die Schülerin oder der Schüler die Farben so schnell wie möglich benennen soll.
	Ihre Aufgabe besteht darin, zu prüfen, ob die Antwort der Schülerin oder des Schülers richtig ist. Wenn die Antwort richtig ist, fahren Sie mit der Einführung der Bilder fort. Wenn die Antwort falsch ist, korrigieren Sie die Schülerin oder den Schüler, indem Sie die richtigen Namen der Farben nennen.`,
	rn_admin7: `Die Beispiele sind nun beendet. Die Schülerin oder der Schüler sieht nun ein Tableau mit vielen farbigen Bildern, die sich wiederholen. Die Aufgabe besteht darin, diese von links nach rechts so schnell wie möglich zu benennen – ähnlich wie beim Lesen eines Textes.`,
	rn_admin7Chid:`Hören Sie der Schülerin bzw. dem Schüler gut zu und markieren Sie die Antworten mit richtig oder falsch, indem Sie jeweils auf die unteren Buttons klicken oder folgende Tastenkombinationen verwenden:	`,
	rn_admin8: `Der Testkandidat sieht auf seinem Bildschirm eine Reihe von Bildern, die er so schnell und genau wie möglich vorlesen muss.`,
	rn_admin8Child:`Hören Sie der Schülerin bzw. dem Schüler gut zu und markieren Sie die Antworten mit richtig oder falsch, indem Sie jeweils auf die unteren Buttons klicken oder folgende Tastenkombinationen verwenden:`,
	rn_admin9: `Auf die Antworten der Schülerin oder des Schülers und das Ende der Zeit warten.Sie sehen dasselbe Tableau, das auch die Schülerin oder der Schüler sieht. Ihre Aufgabe besteht darin, zu prüfen, ob die Benennungen richtig sind. Klicken Sie jedes falsch benannte Objekt an.`,
	rn_timeUsed: `Zeitaufwand: {0} Sekunden`,
	rn_intro1: `In dieser Aufgabe siehst Du Bilder. Deine Aufgabe besteht darin, so schnell und genau wie möglich zu sagen, was Du auf den Bildern siehst. Die Bilder erscheinen in verschiedenen Farben. Wenn Du die Bilder benennst, nenne immer auch die Farbe dazu. Für diese Aufgabe hast Du vier Minuten Zeit – arbeite also so schnell und genau wie möglich. Wir beginnen mit einer Einführung der Farben und Bilder und einem Beispiel, damit Du verstehst, wie die Aufgabe funktioniert.`,
	rn_intro2: ``,
	rn_intro3: `Höre genau auf die Namen der Objekte: Hund – Schuh – Baum – Tür – Mond. Nun bist Du dran: Benenne die Objekte so schnell und akkurat, wie Du kannst!`,
	rn_intro4: `Nun siehst Du die Objekte in verschiedenen Farben. Höre genau auf die Namen der farbigen Objekte: roter Hund – blauer Schuh – grüner Baum – gelbe Tür – brauner Mond. Nun bist Du dran: Benenne die farbigen Objekte so schnell und akkurat, wie Du kannst!`,
	rn_intro5: `Wenn die Aufgabe gleich beginnt, siehst Du die farbigen Bilder mehrere Male auf dem Bildschirm. Deine Aufgabe besteht darin, diese so schnell und genau wie möglich von links nach rechts zu benennen. `,
	rn_intro5_child:`Wenn die Aufgabe gleich beginnt, siehst Du die farbigen Bilder mehrere Male auf dem Bildschirm. Benenne die farbigen Bilder so schnell wie möglich von links nach rechts.	`,
	rn_intro1_child: `Die dritte Aufgabe heißt „Bilder benennen“. In dieser Aufgabe siehst Du Bilder. Sie sind in verschiedenen Farben gemalt. Benenne die Bilder so schnell du kannst und nenne immer auch die Farbe dazu. Lass uns mit einer Einführung der Bilder und Farben beginnen, damit Du Dir die Aufgabe besser vorstellen kannst.`,
	rn_intro2_child: `Klicke auf „Los”, um mit den Beispielen zu beginnen.`,
	rn_intro3_child: ``,
	rn_intro4_child: ``,
	rn_intro5_child: `Wenn die Aufgabe gleich beginnt, siehst Du die farbigen Bilder mehrere Male auf dem Bildschirm. Benenne die farbigen Bilder so schnell wie möglich von links nach rechts.`,
	rn_intro6_child: ``,
	rn_Final_Not_child: `Die Nennung des Plurals von „Schuh“ gilt als richtig.`,
	sp_testname: `Laute vertauschen `,
	sp_admin_listen: `Auf die Antworten der Schülerin oder des Schülers warten.	Ihre Aufgabe besteht darin, zu prüfen, ob die Laute richtig vertauscht und die Wörter richtig nachgesprochen wurden.`,
	sp_admin1: `Die Schülerin oder der Schüler hört in dieser Aufgabe Wortpaare. Diese Wortpaare sollen nachgesprochen werden, indem die Anfangslaute vertauscht werden. Die neuen Wörter sind entweder reale Wörter oder Nichtwörter.`,
	sp_admin1part2: `Ihre Aufgabe besteht darin, zu prüfen, ob die Laute richtig vertauscht und die Wörter richtig nachgesprochen wurden. Dies tun Sie, indem Sie bei jeder richtigen Antwort die Taste „A“ und bei jeder falschen Antwort die Taste „S“ auf Ihrer Computertastatur klicken. Die richtige, erwartete Antwort wird Ihnen jeweils angezeigt. Außerdem sehen Sie stets, wie viele Wortpaare noch kommen.`,
	sp_1correct: `1 richtig (S)`,
	sp_2correct: `2 richtig (A)`,
	sp_bothWrong: `Beide falsch (D)`,
	sp_admin2: `Dem Testkandidaten werden einige vorzulesende Wörter mit einem dazwischen geschalteten Ton vorgelesen. Sie müssen dann zuhören, um zu sehen, ob die wiedergegebenen Wörter mit denen auf Ihrem Bildschirm übereinstimmen.`,
	sp_admin3: `Hören Sie dem Testteilnehmer zu und hören Sie, ob er das richtige Wort sagt, das auf dem Bildschirm angezeigt wird.`,
	sp_instruction: ``,
	sp_intro1: `Du hörst in dieser Aufgabe immer ein Wortpaar. Deine Aufgabe besteht darin, diese Wortpaare nachzusprechen und dabei jeweils die Anfangslaute der Wörter zu vertauschen. Die neuen Wörter sind entweder Wörter, die es gibt oder es sind Quatschwörter. `,
	sp_intro_reminder: `es ist nicht der Brief`,
	sp_intro_reminder2: `erster Ton`,
	sp_intro3: `Tippen sie auf „Start“, um mit der Aufgabe zu beginnen.`,
	ws_testname: `Zahlen und Wörter merken`,
	ws_testname_admin: `Zahlen merken`,
	wsn_testname: `Zahlen merken`,
	wsw_testname: `Wörter merken`,
	ws_admin1: `Diese Aufgabe misst die Leistungen des Arbeitsgedächtnisses.`,
	ws_admin1Child:``,
	wsn_admin2: `Die Schülerin/der Schüler hört in dieser Aufgabe Zahlen, die sie/er rückwärts wiederholen soll. Ihre Aufgabe besteht darin, zu prüfen, ob die Antwort der Schülerin/des Schülers richtig ist. Die richtige Antwort wird Ihnen jeweils angezeigt. Klicken Sie nach jeder Antwort entweder auf „richtig“ oder „falsch“. Achtung: Nach zwei falschen Antworten in Folge wird die Aufgabe automatisch abgebrochen.`,
	wsn_admin2part2: ``,
	
	wsn_admin2_child:`Diese Aufgabe misst die Leistungen des Arbeitsgedächtnisses mit Zahlen.`,
	wsn_admin2part2_child:`Die Schülerin/der Schüler hört in dieser Aufgabe Zahlen, die sie/er rückwärts wiederholen soll. Ihre Aufgabe besteht darin, zu prüfen, ob die Antwort der Schülerin/des Schülers richtig ist. Die richtige Antwort wird Ihnen jeweils angezeigt. Klicken Sie nach jeder Antwort entweder auf „richtig“ oder „falsch“. Achtung: Nach zwei falschen Antworten in Folge wird die Aufgabe automatisch abgebrochen.`,
	wsn_admin3: `Die Schülerin/der Schüler hört in dieser Aufgabe Zahlen, die sie/er rückwärts wiederholen soll. Ihre Aufgabe besteht darin, zu prüfen, ob die Antwort der Schülerin/des Schülers richtig ist. Die richtige Antwort wird Ihnen jeweils angezeigt.`,
	
	wsn_admin3Child:`Auf Antwort der Schülerin/des Schülers warten`,
	waitingForTestChild:`Jetzt startet die Aufgabe, ab hier bitte keine Rückmeldung mehr geben.`,
	listenCarefullyChild:`Hören Sie der Schülerin bzw. dem Schüler gut zu und markieren Sie die Antworten mit richtig oder falsch, indem Sie jeweils auf die unteren Buttons klicken oder folgende Tastenkombinationen verwenden:`,
	wsw_admin2: `Die Schülerin oder der Schüler hört in dieser Aufgabe Wortfolgen, die sie oder er rückwärts nachsprechen soll. Ihre Aufgabe besteht darin, zu prüfen, ob die Antwort der Schülerin oder des Schülers richtig ist. Dies tun Sie, indem Sie für eine richtige Antwort die Taste „A“ und für eine falsche Antwort die Taste „S“ auf Ihrer Computertastatur klicken. Die richtige, erwartete Antwort wird Ihnen jeweils angezeigt. Außerdem sehen Sie stets, wie viele Wortfolgen noch kommen.`,
	wsw_admin2part2: ``,
	
	wsw_admin1Child:`Diese Aufgabe misst die Leistungen des Arbeitsgedächtnisses mit Wortmaterial.`,
	wsw_admin1part2Child:`Die Schülerin/der Schüler hört in dieser Aufgabe Wortfolgen, die sie/er rückwärts wiederholen soll. Ihre Aufgabe besteht darin, zu prüfen, ob die Antwort der Schülerin/des Schülers richtig ist. Die richtige Antwort wird Ihnen jeweils angezeigt. Klicken Sie nach jeder Antwort entweder auf „richtig“ oder „falsch“. Achtung: Nach zwei falschen Antworten in Folge wird die Aufgabe automatisch abgebrochen.`,
	
	wsw_admin3: `Hören Sie dem Testteilnehmer zu und hören Sie, ob er die richtige Wortfolge sagt, die auf dem Bildschirm angezeigt wird.`,
	wsw_admin3Child:``,
	wsn_intro_youth1: `Bei dieser Aufgabe hörst Du eine Folge von Zahlen. Deine Aufgabe besteht darin, diese Folgen in umgekehrter Reihenfolge nachzusprechen.`,
	wsn_intro2_youth2: `Wenn ich zum Beispiel "1 - 3" sage, sagst Du "3 - 1". Achtung: Die Zahlenfolgen werden immer länger!`,
	
	wsn_intro1: `Die erste Aufgabe heißt „Zahlen merken“. In dieser Aufgabe hörst Du Zahlenreihen. Sprich die Zahlenreihen rückwärts nach .`,
	wsn_intro2: `Wenn du zum Beispiel 1 – 3 hörst, sagst du 3 – 1. Pass gut auf! Die Zahlenreihen werden immer länger und enden mit einem Piepton. Warte immer auf den Piepton, bevor du sprichst! `,
	wsn_intro3: `Lass uns mit drei Beispielen beginnen, damit Du Dir die Aufgabe besser vorstellen kannst. `,
	wsn_intro4: `Klicke auf „Los”, um mit den Beispielen zu beginnen. `,
	wsw_intro1: `Bei dieser Aufgabe hörst Du eine Folge von Wörtern. Deine Aufgabe besteht darin, diese Folgen in umgekehrter Reihenfolge nachzusprechen. Du kennst diese Aufgabe schon mit Zahlen. Achtung: Die Wortfolgen werden immer länger!`,
	wsw_intro2: `Wenn ich zum Beispiel Ball - Tisch sage, sagst Du Tisch - Ball`,
	wsw_intro_child1:`Die zweite Aufgabe heißt „Wörter merken“. In dieser Aufgabe hörst Du Wortreihen. Sprich die Wortreihen rückwärts nach. Wenn du zum Beispiel Sand – Bein hörst, sagst du Bein – Sand. Du kennst diese Aufgabe schon mit Zahlen. Pass gut auf! Die Wortfolgen werden immer länger und enden mit einem Piepton. Warte immer auf den Piepton, bevor du sprichst.`,
	wsw_intro_child2:`Lass uns mit drei Beispielen beginnen, damit Du Dir die Aufgabe besser vorstellen kannst.`,
	wsw_intro_child3: `Klicke auf „Los”, um mit den Beispielen zu beginnen. `,
	wsn_test1: `Sprich die Zahlenfolgen, die Du hörst, in umgekehrter Reihenfolge nach!`,
	wsn_test1Child:`Höre Dir die Zahlen an und wiederhole sie laut in umgekehrter Reihenfolge.`,
	wsw_test1: `Spreche die Wortfolgen, die Du hörst, in umgekehrter Reihenfolge nach!`,
	wsw_test1Child:`Höre Dir die Wörter an und wiederhole sie laut in umgekehrter Reihenfolge.`,
	om_testname: `Wörter vorlesen`,
	om_intro: `Du siehst in dieser Aufgabe eine Liste mit vielen Wörtern. Deine Aufgabe besteht darin, diese Wörter so schnell und genau wie möglich laut vorzulesen. Die Wörter werden immer länger. In dieser Aufgabe gibt es keine Beispiele. Für diese Aufgabe hast Du eine Minute Zeit – arbeite also so schnell und genau, wie möglich.`,
	om_intro_child: `Die vierte Aufgabe heißt „Wörter lesen“. Du siehst in dieser Aufgabe eine Liste mit vielen Wörtern. Lies die Wörter so schnell und genau wie möglich laut vor. In dieser Aufgabe gibt es keine Beispiele. Du hast eine Minute Zeit.`,
	om_intro_child2:`Klicke auf „Los“, um mit der Aufgabe zu beginnen.`,
	om_intro_child3:`Achtung: Die Aufgabe beginnt sofort.`,
	om_admin1: `Diese Aufgabe misst, wie schnell die Schülerin/der Schüler einzelne Wörter vorlesen kann.`,
	om_admin2: `Die Schülerin oder der Schüler sieht in dieser Aufgabe eine Liste mit Wörtern. Die Wörter werden immer länger. Diese Wörter sollen so schnell und genau wie möglich vorgelesen werden. Ihre Aufgabe besteht darin, zu prüfen, ob die Wörter richtig vorgelesen wurden. Dies tun Sie, indem Sie bei jedem richtig vorgelesenen Wort die Taste „A“ und bei jedem falsch vorgelesenen Wort die Taste „S“ auf Ihrer Computertastatur klicken.`,
	om_admin2_child:`Hier ist es wichtig darauf zu achten, welche Wörter die Schülerin bzw. der Schüler vorliest und jede Antwort mit richtig oder falsch zu markieren. Tun Sie dies mit den folgenden Tastenkombinationen:`,
	om_extranote_child:`Wenn Sie beim Markieren der Antwort einen Fehler machen sollten, können Sie diesen beim nächsten Wort korrigieren. Bei diesem Teiltest ist die Anzahl der richtig gelesenen Wörter entscheidend. `,
	pd_testname: 'Phonologische Bewusstheit ',
	pd_child_testname:'Laute weglassen',
	pd_admin1:'Diese Aufgabe misst die Fähigkeit zur phonologischen Bewusstheit. Die Schülerin/der Schüler hört in dieser Aufgabe jeweils ein Wort. {0} Dann soll ein vorgegebener Laut entfernt und das neu entstandene Wort gesagt werden.',

	pd_admin1_emphasized:`Dieses Wort soll in den Übungsaufgaben zunächst nachgesprochen werden.`,
	pd_admin1_end:`Achten Sie während die Beispiele darauf, dass die richtigen Antworten gegeben werden. Wenn die Antworten nicht stimmen, bitten wir Sie, diese zu korrigieren.`,
	pd_admin2:'Auf die Antworten der Schülerin/des Schülers warten. Ihre Aufgabe besteht darin, zu prüfen, ob die Laute richtig weggelassen und die Wörter richtig nachgesprochen wurden. ',
	pd_intro1:'In dieser Aufgabe wird dir ein Wort gesagt. Wenn du in diesem Wort einen Laut weglässt, wird daraus ein neues Wort. Wenn man zum Beispiel bei „Brot“ das /b/ weglässt, wird daraus „rot“.',
	pd_intro2:'Höre Dir das Wort gut an, entferne dann den genannten Laut und sage das neue Wort.',
	pd_intro3: 'Lass uns mit drei Beispielen beginnen, damit Du Dir die Aufgabe besser vorstellen kannst. Klicke auf „Los”, um mit den Beispielen zu beginnen.',
	pd_introDone:'Wenn die Aufgabe nun beginnt, genügt es, wenn Du nur das neue Wort sagst. Jetzt hast du die drei Beispiele gelöst und es geht mit den richtigen Aufgaben weiter. Deine Lehrkraft darf dir nun nicht mehr helfen. Klicke auf „Los“, um mit der Aufgabe zu beginnen.',
	pd_testInstruction: 'Höre Dir das Wort gut an und wiederhole es. Entferne dann den Laut, den ich dir nenne, und sage das neue Wort.',
	
	pd_intro_child: `Die fünfte Aufgabe heißt „Laute weglassen“. In dieser Aufgabe wird dir ein Wort gesagt. Wenn du in diesem Wort einen Laut weglässt, wird daraus ein neues Wort. Wenn man zum Beispiel bei „Brot“ das /b/ weglässt, wird daraus „rot“. Höre Dir das Wort gut an, entferne dann den genannten Laut und sage das neue Wort.`,
	pd_intro_child2:`Lass uns mit drei Beispielen beginnen, damit Du Dir die Aufgabe besser vorstellen kannst.`,
	pd_intro_child3:`Klicke auf „Los”, um mit den Beispielen zu beginnen.`,
	pd_introDoneChild:'Wenn die Aufgabe nun beginnt, genügt es, wenn Du nur das neue Wort sagst. Jetzt hast du die drei Beispiele gelöst und es geht mit den richtigen Aufgaben weiter. Deine Lehrkraft darf dir nun nicht mehr helfen. Klicke auf „Los“, um mit der Aufgabe zu beginnen.',
	pd_testInstructionChild: 'Höre Dir das Wort gut an und wiederhole es. Entferne dann den Laut, den ich dir nenne, und sage das neue Wort.',
	pd_test_instruction:`Das ursprüngliche Wort muss ab jetzt nicht mehr wiederholt werden.`,
	pd_test_instruction2:`Jetzt startet der Test, ab hier bitte keine Rückmeldung mehr geben.`,
	pd_test_instruction3:`Auf die Antworten der Schülerin/des Schülers warten. Ihre Aufgabe besteht darin, zu prüfen, ob die Laute richtig weggelassen und die Wörter richtig nachgesprochen wurden. `,
	pd_test_instruction4:`Markieren Sie die Antworten mit richtig oder falsch, indem Sie jeweils auf die unteren Buttons klicken oder folgende Tastenkombinationen verwenden:`,
	
	//PhonemeIsolation


	warning1: `Warnung 1 -`,
	warning1text: `Anscheinend wurde der Test mit diesem Testschlüssel noch nicht richtig gestartet. Der Testadministrator muss den richtigen Test von starten`,
	myTests: `Mein Tester`,
	warning2: `Warnung 2`,
	warning2text: `- Anscheinend haben Sie Cross-Site-Tracking in Ihrem Browser deaktiviert. Um unsere Dienste nutzen zu können, müssen Sie es aktivieren. Drücken Sie`,
	linkForInstructions: `hier`,
	remainingTime: `Verbleibende Zeit: `,
	trialIsNowOver: `Die Beispiele sind nun beendet`,
	wassup:`Hallo und herzlich Willkommen zum zweiten Teil von Dysmate!`,
	hvagoan:`Den ersten Teil hast Du mit anderen Kindern gleichzeitig gemacht. Diesen zweiten Teil machst Du mit Deiner Lehrkraft alleine. Du wirst fünf verschiedene Aufgaben bearbeiten. Die fünf Aufgaben dauern zusammen ungefähr 20 Minuten. Viel Spaß!`,
	droptheclick:`Wenn Du bereit bist, klicke auf „LOS“`
};

export default de;
