import React, {Component, Fragment} from 'react';

import ReadyButton from '../../components/ReadyButton';
import AudioPlayer from '../../components/AudioPlayer';

import no_workingMemoryWordsIntro from '../../assets/sounds/no/intros/workingmemory-words-intro.mp3';
import nn_workingMemoryWordsIntro from '../../assets/sounds/nn/intros/workingmemory-words-intro.mp3';
import se_workingMemoryWordsIntro from '../../assets/sounds/se/intros/workingmemory-words-intro.mp3';
import de_workingMemoryWordsIntro from '../../assets/sounds/de/intros/workingmemory-words-intro.wav';
import de_workingMemoryWordsIntroChild from '../../assets/sounds/de/intros/workingmemory-words-intro-child.mp3';

import strings from '../../strings';

class Intro extends Component {
	constructor(props) {
		super(props);
		switch (strings.getLanguage()) {
			case 'nn':
				this.audio = nn_workingMemoryWordsIntro;
				break;
			case 'no':
				this.audio = no_workingMemoryWordsIntro;
				break;

			case 'se':
				this.audio = se_workingMemoryWordsIntro;
				break;
			case 'de':
				switch(this.props.testKeyType){
					case 'child-followup':
					this.audio = de_workingMemoryWordsIntroChild;
					this.introtext1=strings.wsw_intro_child1;
					this.introtext2=strings.wsw_intro_child2;
					this.introtext3=strings.wsw_intro_child3;
					break;
					default:
						this.audio = de_workingMemoryWordsIntro;
						this.introtext1=strings.wsw_intro1;
						this.introtext2='';
						this.introtext3='';
					
						break;
				}
		}
	}

	render() {
		return (
			<Fragment>
				<p>
					<AudioPlayer autoplay inline src={this.audio} />
					{this.introtext1} <br />
				</p>
				<p>{this.introtext2}</p>
				<p>{this.introtext3}</p>
				<ReadyButton onReadyChange={this.props.setReady} />
			</Fragment>
		);
	}
}

export default Intro;
