import React, {Component} from 'react';

import wm_number from '../../assets/sounds/de/pauses/FOL-C-006.mp3';
import wm_trial_sound from '../../assets/sounds/de/pauses/SCR007.wav';
import wmN_trial_child_sound from '../../assets/sounds/de/pauses/SCR007-child.mp3';
import wmW_trial_child_sound from '../../assets/sounds/de/pauses/SCR007-child.mp3';
import wm_words from '../../assets/sounds/de/pauses/FOL-C-013.mp3';
import ran from '../../assets/sounds/de/pauses/FOL045.wav';
import ranChild from '../../assets/sounds/de/pauses/rapidnaming.mp3';
import Onemint from '../../assets/sounds/de/pauses/FOL047.wav';
import OnemintChild from '../../assets/sounds/de/pauses/minChild.mp3';
import spoonerism from '../../assets/sounds/de/pauses/FOL072.wav';
import phonemedeletionSound from '../../assets/sounds/de/pauses/pd-finish-sound.mp3';

import AudioPlayer from '../../components/AudioPlayer';
import strings from '../../strings';

class Pause extends Component {
	constructor(props) {
		super(props);

		this.wm_number_sound = wm_number;
		this.wm_words_sound = wm_words;
		this.wm_number_child_sound = wm_number;	//For child followupo
		this.wm_words_child_sound = wm_words;	//For child followupo	
		this.ran_sound = ranChild;
		this.Onemint_sound = Onemint;
		this.Onemint_soundChild = OnemintChild;
		this.spoonerism_sound = spoonerism;
		this.pd_sound = phonemedeletionSound;
		this.state = {
			testName: this.props.testName ? this.props.testName : '',
			displayBtn: true,
		};
	}

	returnRelevantSound(testName) {
		switch (testName) {
			
			case strings.wsn_testname:
				switch(this.props.testKeyType){
				case 'child-followup':
					if (this.props.pauseSound === 'trial') {
						return wmN_trial_child_sound;
					} else return this.wm_number_sound;
				
				default:
					if (this.props.pauseSound === 'trial') {
						return wm_trial_sound;
					} else return this.wm_number_sound;
				}
				
				

			case strings.wsw_testname:
				switch(this.props.testKeyType){
					case 'child-followup':
						if (this.props.pauseSound === 'trial') {
							return wmW_trial_child_sound;
						} else return this.wm_words_sound;
					
					default:
						if (this.props.pauseSound === 'trial') {
							return wm_trial_sound;
						} else return this.wm_words_sound;
					}

				
			

			case strings.rn_testname:
				return this.ran_sound;
				break;

			case strings.om_testname:
				return this.Onemint_soundChild;
				break;

			case strings.sp_testname:
				return this.spoonerism_sound;
				break;
			case strings.pd_testname:
				return this.pd_sound;
				break;
			case strings.pd_child_testname:
				return this.pd_sound;
				break;
		}
	}

	render() {
		const {testName} = this.state;
		return (
			<div>
				<p>
					{testName && strings.getLanguage() === 'de' && (
						<AudioPlayer
							autoplay
							inline
							src={this.returnRelevantSound(testName)}
						/>
					)}
					{this.props.pauseSound !== 'trial'
						? strings.formatString(
								strings.pauseBeforeNextTest,
								testName
						  )
						: this.props.testKeyType!=='child-followup' ? strings.trialIsNowOver:`Jetzt hast Du die drei Beispiele gelöst und es geht mit den richtigen Aufgaben weiter. Deine Lehrkraft darf dir nun nicht mehr helfen. Klicke auf „Los“, um mit der Aufgabe zu beginnen. `}
				</p>
				{this.props.pauseSound === 'trial' && this.state.displayBtn && (
					<div>
						<button
							onClick={(e) => {
								this.setState({
									displayBtn: false,
								});
							}}
						>
							{strings.start}
						</button>
					</div>
				)}
			</div>
		);
	}
}

export default Pause;
