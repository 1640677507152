import React, {Component} from 'react';

import strings from '../../strings';

import ReadyButton from '../../components/ReadyButton';
import AudioPlayer from '../../components/AudioPlayer';

import {nn_intro, no_intro, de_intro,de_intro_child, de_introDone} from './assets';
import listen from '../../assets/listen.png';

class Intro extends Component {
	constructor(props) {
		super(props);

		this.state = {isShowingPractiseTests: false};

		switch (strings.getLanguage()) {
			case 'nn':
				this.assets = nn_intro;
				break;
			case 'de':
				switch(this.props.testKeyType){
					case 'child-followup':
					
					this.introtext1=strings.pd_intro_child;
					this.introtext2=strings.pd_intro_child2;
					this.introtext3=strings.pd_intro_child3;
					this.introtext4 = strings.pd_testInstructionChild;
					this.introtext5 = strings.pd_introDoneChild;
					this.assets = de_intro_child;
					break;
					default:
						
						this.introtext1=strings.pd_intro1;
						this.introtext2=strings.pd_intro2;
						this.introtext3=strings.pd_intro3;
						this.introtext4=strings.pd_testInstruction;
						this.introtext5=strings.pd_introDone;
						this.assets = de_intro;
						break;
				}
			
				break;
			case 'no':
			default:
				this.assets = no_intro;
				break;
		}
	}

	componentWillReceiveProps(newProps) {
		if (this.props.currentStep !== newProps.currentStep) {
			if (newProps.currentStep || newProps.currentStep === 0) {
				this.setState({
					currentStep: newProps.currentStep,
				});
			}
		}
	}

	getTaskAudio = (step) => {
		switch (step) {
			case 1:
				return this.assets.task1;
			case 2:
				return this.assets.task1a;
			case 3:
				return this.assets.task2;
			case 4:
				return this.assets.task2a;
			case 5:
				return this.assets.task3;
			case 6:
				return this.assets.task3a;
			default:
				return null;
		}
	};

	render() {
		const {currentStep} = this.props;

		let currentTaskAudio = this.getTaskAudio(currentStep);

		return (
			<>
				{currentStep === 0 && (
					<>
						<p>
							<AudioPlayer
								autoplay
								inline
								src={this.assets.intro}
							/>
							{this.introtext1}
						</p>
						<p>{this.introtext2}</p>
						<p>{this.introtext3}</p>

						{<ReadyButton onReadyChange={this.props.setReady} />}
					</>
				)}
				{currentStep > 0 && currentStep < 7 && (
					<div className="WorkingMemory">
						<img className="listen-image" src={listen} alt="" />
						<p>
							<AudioPlayer
								autoplay
								inline
								playOnSourceChanged
								src={currentTaskAudio}
							/>
							{this.introtext4}
						</p>
					</div>
				)}
				{currentStep === 7 && (
					<>
					<p>
							<AudioPlayer
								autoplay
								inline
								playOnSourceChanged
								src={this.assets.introDone}
							/>
							{this.introtext5}
						</p>
						<ReadyButton onReadyChange={this.props.setReady} />
					</>
				)}
			</>
		);
	}
}

export default Intro;
