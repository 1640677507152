import React, { Fragment } from 'react';
import Timer from '../../components/Timer';
import {getOneMinute,getOneMinuteChild} from '../../constants/tests.js';
import {chunkArray} from '../../utils/arrayUtils';

import AdminTestPanel from '../../components/AdminTestPanel';
import strings from '../../strings';
import AdminReadyButton from '../../components/AdminReadyButton';

const ITEMS_PER_COLUMN = 10;

class Admin extends AdminTestPanel {
	constructor(props) {
		super(props, {
			enableTimers: false,
		});

		switch (strings.getLanguage()) {
			
			case 'de':
				
				switch(this.props.testKeyType){
					case 'child-followup':
						this.isChildType = true;
						this.om_admin2 = strings.om_admin2_child;
						this.testDone = strings.testDoneChild;
						this.nextTest = strings.nextSubtest;
						break;
						default:
							this.isChildType = true;
							this.om_admin2 = strings.om_admin2;
							this.testDone = strings.testDone;
							this.nextTest = strings.nextTest;
				}
				break;

				}
	}

	getTestName = () => {
		return strings.om_testname;
	};

	onTimerEnd = () => {
		this.props.nextStage(this.state.results);
	};

	getClass = (row, index) => {
		const actualIndex = row * ITEMS_PER_COLUMN + index;

		const entry = this.state.results[actualIndex];
		if (!entry) return '';

		if (!entry.isAnswered) return ' unanswered';

		if (entry.isCorrect) return ' correct';

		return ' error';
	};

	cycleAnswerStatus = (row, index) => {
		const actualIndex = row * ITEMS_PER_COLUMN + index;
		const itemsCopy = JSON.parse(JSON.stringify(this.state.results));
		const entry = itemsCopy[actualIndex];

		let currentStatus;
		if (!entry) currentStatus = 'unanswered';
		else if (!entry.isAnswered) currentStatus = 'unanswered';
		else if (entry.isCorrect) currentStatus = 'correct';
		else if (!entry.isCorrect) currentStatus = 'wrong';

		let newState = {};
		if (currentStatus === 'unanswered')
			newState = {isAnswered: true, isCorrect: true};
		else if (currentStatus === 'correct')
			newState = {isAnswered: true, isCorrect: false};
		else if (currentStatus === 'wrong')
			newState = {isAnswered: false, isCorrect: false};

		itemsCopy[actualIndex] = Object.assign(
			{},
			itemsCopy[actualIndex],
			newState
		);

		this.setState({
			results: itemsCopy,
		});
	};

	renderIntro = () => {
		return (
			<div>
				<p>
					{strings.om_admin1}
					<br />
				</p>
				<p>{this.om_admin2}</p>
				{this.isChildType===true &&(
					<Fragment>
						<p>{strings.hotkeyCorrect}
						<br/>
						{strings.hotkeyWrong}
						<br/>
						{strings.hotkeyUnanswered}
						</p>
				<p>{strings.om_extranote_child}</p>
					</Fragment>
				)}
				<p>{strings.clickStartWhenReady}</p>
				<AdminReadyButton
					remoteIsReady={this.props.candidateReady}
					onClick={this.props.nextStage}
				/>
			</div>
		);
	};

	renderTest = () => {
		const {currentStep} = this.state;
		const TIMER_DURATION = 60;
		const steps =this.props.testKeyType ==='child-followup' ? getOneMinuteChild().getSteps() : getOneMinute().getSteps();
		const rows = this.props.testKeyType ==='child-followup'? chunkArray(getOneMinuteChild().getSteps(), ITEMS_PER_COLUMN): chunkArray(getOneMinute().getSteps(), ITEMS_PER_COLUMN);
		const currentRow = Math.floor(currentStep / ITEMS_PER_COLUMN);
		const currentItem = Math.floor(currentStep % ITEMS_PER_COLUMN);

		const row = rows[currentRow] || null;
		const previousRow = rows[currentRow - 1] || null;
		const nextRow = rows[currentRow + 1] || null;
		
		return (
			<div className="oneminute-admin">
				<p>{strings.om_admin1}</p>
				<div className="admin-inline">
					<p>
						{strings.formatString(
							strings.taskNumOf,
							currentStep + 1,
							steps.length
						)}{' '}
					</p>
					<Timer
						duration={TIMER_DURATION}
						onEnd={this.onTimerEnd}
						autoStart
					/>
				</div>
				<div className="TestWrapper">
					<div className="column previous">
						{previousRow &&
							previousRow.map((item, index) => (
								<p
									key={index}
									className={`item ${this.getClass(
										currentRow - 1,
										index
									)}`}
									onClick={() =>
										this.cycleAnswerStatus(
											currentRow - 1,
											index
										)
									}
								>
									{item}
								</p>
							))}
					</div>
					<div className="column">
						{row &&
							row.map((item, index) => (
								<p
									key={index}
									className={`item${
										currentItem === index ? ' active' : ''
									}${this.getClass(currentRow, index)}`}
									onClick={() =>
										this.cycleAnswerStatus(
											currentRow,
											index
										)
									}
								>
									{item}
								</p>
							))}
					</div>
					<div className="column next">
						{nextRow &&
							nextRow.map((item, index) => (
								<p
									key={index}
									className={`item ${this.getClass(
										currentRow + 1,
										index
									)}`}
									onClick={() =>
										this.cycleAnswerStatus(
											currentRow + 1,
											index
										)
									}
								>
									{item}
								</p>
							))}
					</div>
				</div>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerCorrect();
					}}
				>
					{strings.hotkeyButtonCorrect}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerWrong();
					}}
				>
					{strings.hotkeyButtonWrong}
				</button>
				<button
					onClick={(e) => {
						e.stopPropagation();
						this.answerUnanswered();
					}}
				>
					{strings.hotkeyButtonUnanswered}
				</button>
			</div>
		);
	};

	renderWaiting = () => {
		return (
			<div>
				{this.isChildType ===false && (
					<p>
					{strings.formatString(
						this.testDone,
						strings.testName_OneMinute
					)}
				</p>
				)}
				{this.isChildType ===true && (
					<Fragment>
					<p>
					{strings.formatString(
						this.testDone,
						strings.testName_OneMinute
					)}
				</p>
					 <p>{strings.formatString(strings.ensureReadiness,strings.nextSubtest)}</p>
				</Fragment>

				)}
				<button onClick={this.props.nextStage}>
				{this.isChildType===false ? this.nextTest :strings.nextSubtest}
				</button>
			</div>
		);
	};
}

export default Admin;
