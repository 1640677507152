import {Howl} from 'howler';

import no_introSound from '../../assets/sounds/no/intros/pd-intro.mp3';
import no_gris from '../../assets/sounds/no/phonemeDeletion/gris.mp3';
import no_ris from '../../assets/sounds/no/phonemeDeletion/gris-g.mp3';
import no_foere from '../../assets/sounds/no/phonemeDeletion/foere.mp3';
import no_oere from '../../assets/sounds/no/phonemeDeletion/foere-f.mp3';
import no_sel from '../../assets/sounds/no/phonemeDeletion/sel.mp3';
import no_se from '../../assets/sounds/no/phonemeDeletion/sel-l.mp3';
import no_gaas from '../../assets/sounds/no/phonemeDeletion/gaas-g.mp3';
import no_maal from '../../assets/sounds/no/phonemeDeletion/maal-m.mp3';
import no_broed from '../../assets/sounds/no/phonemeDeletion/broed-b.mp3';
import no_klaer from '../../assets/sounds/no/phonemeDeletion/klaer-k.mp3';
import no_brev from '../../assets/sounds/no/phonemeDeletion/brev-b.mp3';
import no_trygg from '../../assets/sounds/no/phonemeDeletion/trygg-t.mp3';
import no_drap from '../../assets/sounds/no/phonemeDeletion/drap-d.mp3';
import no_skyll from '../../assets/sounds/no/phonemeDeletion/skyll-l.mp3';
import no_laas from '../../assets/sounds/no/phonemeDeletion/laas-s.mp3';
import no_bro from '../../assets/sounds/no/phonemeDeletion/bro-r.mp3';
import no_klatre from '../../assets/sounds/no/phonemeDeletion/klatre-t.mp3';
import no_malt from '../../assets/sounds/no/phonemeDeletion/malt-l.mp3';
import no_bry from '../../assets/sounds/no/phonemeDeletion/bry-r.mp3';
import no_lekse from '../../assets/sounds/no/phonemeDeletion/lekse-k.mp3';
import no_sport from '../../assets/sounds/no/phonemeDeletion/sport-p.mp3';
import no_loff from '../../assets/sounds/no/phonemeDeletion/loff-f.mp3';

import nn_introSound from '../../assets/sounds/nn/intros/pd-intro.mp3';
import nn_gris from '../../assets/sounds/nn/phonemeDeletion/gris.mp3';
import nn_ris from '../../assets/sounds/nn/phonemeDeletion/gris-g.mp3';
import nn_foere from '../../assets/sounds/nn/phonemeDeletion/foere.mp3';
import nn_oere from '../../assets/sounds/nn/phonemeDeletion/foere-f.mp3';
import nn_sel from '../../assets/sounds/nn/phonemeDeletion/sel.mp3';
import nn_se from '../../assets/sounds/nn/phonemeDeletion/sel-l.mp3';
import nn_gaas from '../../assets/sounds/nn/phonemeDeletion/gaas-g.mp3';
import nn_maal from '../../assets/sounds/nn/phonemeDeletion/maal-m.mp3';
import nn_broed from '../../assets/sounds/nn/phonemeDeletion/broed-b.mp3';
import nn_klede from '../../assets/sounds/nn/phonemeDeletion/klede-k.mp3';
import nn_brev from '../../assets/sounds/nn/phonemeDeletion/brev-b.mp3';
import nn_trygg from '../../assets/sounds/nn/phonemeDeletion/trygg-t.mp3';
import nn_drap from '../../assets/sounds/nn/phonemeDeletion/drap-d.mp3';
import nn_skyl from '../../assets/sounds/nn/phonemeDeletion/skyl-l.mp3';
import nn_laas from '../../assets/sounds/nn/phonemeDeletion/laas-s.mp3';
import nn_bro from '../../assets/sounds/nn/phonemeDeletion/bru-r.mp3';
import nn_klatre from '../../assets/sounds/nn/phonemeDeletion/klatre-t.mp3';
import nn_malt from '../../assets/sounds/nn/phonemeDeletion/malt-l.mp3';
import nn_bry from '../../assets/sounds/nn/phonemeDeletion/bry-r.mp3';
import nn_lekse from '../../assets/sounds/nn/phonemeDeletion/lekse-k.mp3';
import nn_sport from '../../assets/sounds/nn/phonemeDeletion/sport-p.mp3';
import nn_loff from '../../assets/sounds/nn/phonemeDeletion/loff-f.mp3';

import de_introSound_child from '../../assets/sounds/de/intros/pd-intro-child.mp3';

import de_introSound from '../../assets/sounds/de/intros/pd-intro.mp3';
import de_introDoneSound from '../../assets/sounds/de/intros/pd_intro_done.mp3';

import de_reis_child from '../../assets/sounds/de/phonemeDeletion/reis-child.mp3';
import de_reis from '../../assets/sounds/de/phonemeDeletion/reis.mp3';
import de_bauch_child from '../../assets/sounds/de/phonemeDeletion/bauch-child.mp3';

import de_eis from '../../assets/sounds/de/phonemeDeletion/eis-r.mp3';
import de_bauch from '../../assets/sounds/de/phonemeDeletion/bauch.mp3';
import de_bau from '../../assets/sounds/de/phonemeDeletion/bau-ch.mp3';
import de_knopf from '../../assets/sounds/de/phonemeDeletion/knopf.mp3';
import de_knopf_child from '../../assets/sounds/de/phonemeDeletion/knopf-child.mp3';

import de_kopf from '../../assets/sounds/de/phonemeDeletion/kopf-n.mp3';
import de_decke from '../../assets/sounds/de/phonemeDeletion/decke-d.mp3';
import de_saal from '../../assets/sounds/de/phonemeDeletion/saal-s.mp3';
import de_mund from '../../assets/sounds/de/phonemeDeletion/mund-m.mp3';
import de_haus from '../../assets/sounds/de/phonemeDeletion/haus-h.mp3';
import de_tor from '../../assets/sounds/de/phonemeDeletion/tor-t.mp3';
import de_riegel from '../../assets/sounds/de/phonemeDeletion/riegel-r.mp3';
import de_warm from '../../assets/sounds/de/phonemeDeletion/warm-m.mp3';
import de_mond from '../../assets/sounds/de/phonemeDeletion/mond-m.mp3';
import de_rot from '../../assets/sounds/de/phonemeDeletion/rot-t.mp3';
import de_eis2 from '../../assets/sounds/de/phonemeDeletion/eis-s.mp3';
import de_rechts from '../../assets/sounds/de/phonemeDeletion/rechts-s.mp3';
import de_alt from '../../assets/sounds/de/phonemeDeletion/alt-t.mp3';
import de_maus from '../../assets/sounds/de/phonemeDeletion/maus-m.mp3';
import de_glas from '../../assets/sounds/de/phonemeDeletion/glas-l.mp3';
import de_brot from '../../assets/sounds/de/phonemeDeletion/brot-r.mp3';
import de_stau from '../../assets/sounds/de/phonemeDeletion/stau-t.mp3';
import de_frisch from '../../assets/sounds/de/phonemeDeletion/frisch-r.mp3';
import de_kran from '../../assets/sounds/de/phonemeDeletion/kran-r.mp3';
import de_graben from '../../assets/sounds/de/phonemeDeletion/graben-r.mp3';
import de_trauen from '../../assets/sounds/de/phonemeDeletion/trauen-t.mp3';

export const no_intro = {
	intro: no_introSound,
	task1: no_gris,
	task1a: no_ris,
	task2: no_foere,
	task2a: no_oere,
	task3: no_sel,
	task3a: no_se,
};

export const nn_intro = {
	intro: nn_introSound,
	task1: nn_gris,
	task1a: nn_ris,
	task2: nn_foere,
	task2a: nn_oere,
	task3: nn_sel,
	task3a: nn_se,
};

export const de_intro = {
	intro: de_introSound,
	task1: de_reis,
	task1a: de_eis,
	task2: de_bauch,
	task2a: de_bau,
	task3: de_knopf,
	task3a: de_kopf,
	introDone: de_introDoneSound,
};


export const de_intro_child  = {
	intro: de_introSound_child,
	task1: de_reis_child,
	task1a: de_eis,
	task2: de_bauch_child,
	task2a: de_bau,
	task3: de_knopf_child,
	task3a: de_kopf,
	introDone: de_introDoneSound,
};

export const no_test = [
	new Howl({src: no_gaas}),
	new Howl({src: no_maal}),
	new Howl({src: no_broed}),
	new Howl({src: no_klaer}),
	new Howl({src: no_brev}),
	new Howl({src: no_trygg}),
	new Howl({src: no_drap}),
	new Howl({src: no_skyll}),
	new Howl({src: no_laas}),
	new Howl({src: no_bro}),
	new Howl({src: no_klatre}),
	new Howl({src: no_malt}),
	new Howl({src: no_bry}),
	new Howl({src: no_lekse}),
	new Howl({src: no_sport}),
	new Howl({src: no_loff}),
];

export const de_test = [
	new Howl({src: de_decke}),
	new Howl({src: de_saal}),
	new Howl({src: de_mund}),
	new Howl({src: de_haus}),
	new Howl({src: de_tor}),
	new Howl({src: de_riegel}),
	new Howl({src: de_warm}),
	new Howl({src: de_mond}),
	new Howl({src: de_rot}),
	new Howl({src: de_eis2}),
	new Howl({src: de_rechts}),
	new Howl({src: de_alt}),
	new Howl({src: de_maus}),
	new Howl({src: de_glas}),
	new Howl({src: de_brot}),
	new Howl({src: de_stau}),
	new Howl({src: de_frisch}),
	new Howl({src: de_kran}),
	new Howl({src: de_graben}),
	new Howl({src: de_trauen}),
];
export const nn_test = [
	new Howl({src: nn_gaas}),
	new Howl({src: nn_maal}),
	new Howl({src: nn_broed}),
	new Howl({src: nn_klede}),
	new Howl({src: nn_brev}),
	new Howl({src: nn_trygg}),
	new Howl({src: nn_drap}),
	new Howl({src: nn_skyl}),
	new Howl({src: nn_laas}),
	new Howl({src: nn_bro}),
	new Howl({src: nn_klatre}),
	new Howl({src: nn_malt}),
	new Howl({src: nn_bry}),
	new Howl({src: nn_lekse}),
	new Howl({src: nn_sport}),
	new Howl({src: nn_loff}),
];
