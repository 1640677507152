import no_intro1 from '../../assets/sounds/no/rapidNaming/rapidNaming-intro1.mp3';
import no_intro2 from '../../assets/sounds/no/rapidNaming/rapidNaming-intro2.mp3';
import no_intro3 from '../../assets/sounds/no/rapidNaming/rapidNaming-intro3.mp3';
import no_intro4 from '../../assets/sounds/no/rapidNaming/rapidNaming-intro4.mp3';
import no_intro5 from '../../assets/sounds/no/rapidNaming/rapidNaming-intro5.mp3';

import nn_intro1 from '../../assets/sounds/nn/rapidNaming/rapidNaming-intro1.mp3';
import nn_intro2 from '../../assets/sounds/nn/rapidNaming/rapidNaming-intro2.mp3';
import nn_intro3 from '../../assets/sounds/nn/rapidNaming/rapidNaming-intro3.mp3';
import nn_intro4 from '../../assets/sounds/nn/rapidNaming/rapidNaming-intro4.mp3';
import nn_intro5 from '../../assets/sounds/nn/rapidNaming/rapidNaming-intro5.mp3';

import se_intro1 from '../../assets/sounds/se/rapidNaming/rapidNaming-intro1.mp3';
import se_intro2 from '../../assets/sounds/se/rapidNaming/rapidNaming-intro2.mp3';
import se_intro3 from '../../assets/sounds/se/rapidNaming/rapidNaming-intro3.mp3';
import se_intro4 from '../../assets/sounds/se/rapidNaming/rapidNaming-intro4.mp3';
import se_intro5 from '../../assets/sounds/se/rapidNaming/rapidNaming-intro5.mp3';

import de_intro1 from '../../assets/sounds/de/rapidNaming/rapidNaming-intro1.wav';
import de_intro2 from '../../assets/sounds/de/rapidNaming/rapidNaming-intro2.wav';
import de_intro3 from '../../assets/sounds/de/rapidNaming/rapidNaming-intro3.wav';
import de_intro4 from '../../assets/sounds/de/rapidNaming/rapidNaming-intro4.wav';
import de_intro5 from '../../assets/sounds/de/rapidNaming/rapidNaming-intro5.wav';

import de_intro1_child from '../../assets/sounds/de/rapidNaming/rapidNaming-intro1_child.mp3';
import de_intro2_child from '../../assets/sounds/de/rapidNaming/rapidNaming-intro2_child.mp3';
import de_intro3_child from '../../assets/sounds/de/rapidNaming/rapidNaming-intro3_child.mp3';
import de_intro4_child from '../../assets/sounds/de/rapidNaming/rapidNaming-intro4_child.mp3';
import de_intro5_child from '../../assets/sounds/de/rapidNaming/rapidNaming-intro5_child.mp3';


export const no = {
	intro1: no_intro1,
	intro2: no_intro2,
	intro3: no_intro3,
	intro4: no_intro4,
	intro5: no_intro5,
};

export const nn = {
	intro1: nn_intro1,
	intro2: nn_intro2,
	intro3: nn_intro3,
	intro4: nn_intro4,
	intro5: nn_intro5,
};

export const se = {
	intro1: se_intro1,
	intro2: se_intro2,
	intro3: se_intro3,
	intro4: se_intro4,
	intro5: se_intro5,
};

export const de = {
	intro1: de_intro1,
	intro2: de_intro2,
	intro3: de_intro3,
	intro4: de_intro4,
	intro5: de_intro5,
};

export const de_child = {
	intro1: de_intro1_child,
	intro2: de_intro2_child,
	intro3: de_intro3_child,
	intro4: de_intro4_child,
	intro5: de_intro5_child,
};
