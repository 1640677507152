import React, {Component} from 'react';

import Intro from './Intro';
import Test from './Test';
import Pause from '../../components/Pause';
import strings from '../../strings';

const subStages = [Intro, Test, Pause];

class PhonemeDeletion extends Component {
	constructor(props) {
		super(props);

		this.state = {
			currentStage: 0,
			currentStep: 0,
		};
	}

	componentWillReceiveProps(newProps) {
		const newState = {};
		let flag = false;
		if (newProps.currentStep !== this.props.currentStep) {
			newState.currentStep = newProps.currentStep;
			flag = true;
		}
		if (newProps.currentStage !== this.props.currentStage) {
			newState.currentStage = newProps.currentStage;
			flag = true;
		}
		if (flag) {
			this.setState(newState);
		}
	}

	render() {
		const {currentStage} = this.state;

		const CurrentSubStage = subStages[currentStage];
		const testName = ['2','3','4','5','6'].includes(this.props.ageGroup) ? strings.pd_child_testname:strings.pd_testname;
		return (
			<div className="PhonemeDeletion">
				<h1 className="lit-page-title">{testName}</h1>
				<CurrentSubStage
					currentStep={this.state.currentStep}
					nextStep={this.props.nextStep}
					setReady={this.props.setReady}
					testKeyType={this.props.testKeyType}
					ageGroup={this.props.ageGroup}
					startTimer={this.props.startTimer}
					endTimer={this.props.endTimer}
					testName={testName}
					pauseSound={'test'}
				/>
			</div>
		);
	}
}

export default PhonemeDeletion;
