import React, {Component} from 'react';
import ferdigLyd from '../../assets/sounds/no/intros/testen-er-ferdig.mp3';
import ferdigLydDe from '../../assets/sounds/de/intros/testen-er-ferdig.wav';
import ferdigLydDeChild from '../../assets/sounds/de/intros/testen-ferdig-child.mp3';
import AudioPlayer from '../../components/AudioPlayer';

import './styles.scss';
import strings from '../../strings';

class TestComplete extends Component {
	constructor(props) {
		super(props);
		switch (strings.getLanguage()) {
			case 'nn':
				this.finalSound = ferdigLyd;
				break;
			case 'no':
				this.finalSound = ferdigLyd;
				break;
			case 'se':
				this.finalSound = ferdigLyd;
				break;
			case 'de':
				switch(this.props.testKeyType){
					case 'child-followup':
					this.finalSound = ferdigLydDeChild;
					this.finishText = strings.TestCompleteSalutation;
					break;
					default:
					this.finishText = strings.TesFinished_Instruction_ONCANDIATESIDE;
					this.finalSound = ferdigLydDe;
					break;
				}
				
				break;
			default:
				this.finalSound = ferdigLyd;
				break;
		}

		this.state = {};
	}

	render() {
		const isCandidate = this.props.isCandidate || false;

		return (
			<div className="TestComplete">
				<h2>
					{isCandidate && (
						<AudioPlayer autoplay inline src={this.finalSound} />
					)}
					{isCandidate
						? this.finishText
						: strings.testIsFinished}
				</h2>
				<p>
					{isCandidate && strings.getLanguage() === 'de'
						? ''
						: strings.testFinishedInstruction}
				</p>
				<button
					onClick={() => {
						window.localStorage.removeItem('authtoken-child');
						setTimeout(function () {
							window.location = '/';
						});
					}}
				>
					{strings.closeTest}
				</button>
			</div>
		);
	}
}

export default TestComplete;
