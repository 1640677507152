import React, {Component, Fragment} from 'react';
import AdminReadyButton from '../AdminReadyButton';
import strings from '../../strings';
import {LANGUAGE,CHILD_TEST_URL} from '../../constants';

const excludeForLang = ['de', 'se'];
class TestIntro extends Component {
	constructor(props) {
		super(props);

		this.state = {
			candidateInfo: {
				gender: excludeForLang.includes(strings.getLanguage())
					? ''
					: 'm',
				class: this.props.ageGroup ? this.props.ageGroup : 0,
			},
			ageGroup: this.props.ageGroup ? this.props.ageGroup : null,
			languageSelected:
				this.getAvailableLanguages().length === 1
					? this.getAvailableLanguages()[0]
					: null,
			education: null,
		};
	}

	getAvailableLanguages = () => {
		let lang = null;
		switch (LANGUAGE) {
			case 'se':
				lang = ['se'];
				break;
			case 'de':
				lang = ['de'];
				break;
			case 'no':
			case 'nn':
			default:
				lang = ['no', 'nn'];
				break;
		}
		if (lang.length === 1) {
			this.onLanguageSelect(lang[0]);
		}

		return lang;
	};

	onLanguageSelect = (lang) => {
		console.log('SET LANGUAGE', lang);
		this.props.updateLanguage(lang);
		strings.setLanguage(lang);
		this.setState({
			languageSelected: lang,
		});
	};

	handleInputChange = (event) => {
		const target = event.target;
		const value =
			target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;

		this.setState({
			candidateInfo: {
				...this.state.candidateInfo,
				[name]: value,
			},
		});
	};

	setEducation = (e) => {
		this.setState({
			education: e.target.value,
		});
	};

	handleReadyClick = (e) => {
		e.preventDefault();

		const {firstName, lastName, class: ageGroup} = this.state.candidateInfo;

		const updatedClass =
			this.state.education === 'other' ? 'other' : ageGroup;
		this.setState(
			{
				candidateInfo: {
					...this.state.candidateInfo,
					name: firstName + ' ' + lastName,
					class: updatedClass,
					testType: this.props.testKeyType,
				},
				ageGroup: updatedClass,
			},
			() => {
				this.props.onReadyClick(this.state.candidateInfo);
			}
		);
		return false;
	};

	render() {
		const {roomKey, isClientReady} = this.props;
		const {languageSelected} = this.state;
		if (!languageSelected) {
			return (
				<div>
					<h2>{strings.chooseLanguage}</h2>
					<div className="LanguageButton_wrapper">
						{this.getAvailableLanguages().map((lang, index) => (
							<button
								tabIndex={index + 1}
								className="LanguageButton"
								onClick={() => this.onLanguageSelect(lang)}
							>
								{strings['lang_' + lang]}
							</button>
						))}
					</div>
				</div>
			);
		}
		return (
			<div className="TestIntro">
				<div className="info-box">
					<p className="info-box__title">{strings.welcomeToTest}</p>
					<p className="info-box__hint">{strings.helpBox1}</p>
					<ol>
		<li>{strings.formatString(strings.helpBoxList1,<b>{CHILD_TEST_URL}</b>)}</li>
						<li>{strings.helpBoxList2}</li>
						<li>
							{strings.formatString(
								strings.helpBoxList3,
								<b>{roomKey}</b>
							)}
						</li>
						<li>{strings.helpBoxList4}</li>
						<li>{strings.helpBoxList5}</li>
					</ol>
				</div>

				<div>
					{!excludeForLang.includes(strings.getLanguage()) && (
						<h2>{strings.infoAboutCandidate}</h2>
					)}
					<form onSubmit={this.handleReadyClick} className="form">
						<Fragment>
							{!excludeForLang.includes(
								strings.getLanguage()
							) && <h2>{strings.generalInformation}</h2>}
							{!excludeForLang.includes(
								strings.getLanguage()
							) && (
								<p className="warning">
									{strings.formatString(
										strings.candidateReminder,
										<b>{strings.notaBene}</b>
									)}
								</p>
							)}

							<br />
							{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
								!this.props.ageGroup) && (
								<label className="inline">
									{strings.firstName}{' '}
									<input
										type="text"
										name="firstName"
										placeholder={strings.firstName}
										onChange={this.handleInputChange}
										required
									/>
								</label>
							)}
							{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
								!this.props.ageGroup) && (
								<label className="inline">
									{strings.lastName}{' '}
									<input
										type="text"
										name="lastName"
										placeholder={strings.lastName}
										onChange={this.handleInputChange}
										required
									/>
								</label>
							)}

							{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
								!this.props.ageGroup) && (
								<label>
									{strings.birthDate}
									<input
										type="date"
										name="birthdate"
										onChange={this.handleInputChange}
										required
									/>
								</label>
							)}

							{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
								!this.props.ageGroup) && (
								<label>
									{strings.gender}
									<select
										name="gender"
										onChange={this.handleInputChange}
										required
									>
										<option value="">
											{strings.pickOne}
										</option>
										<option value="m">{strings.boy}</option>
										<option value="f">
											{strings.girl}
										</option>
										<option value="u">
											{strings.unspecified}
										</option>
									</select>
								</label>
							)}
						</Fragment>
						<Fragment>
							{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
								!this.props.ageGroup) && (
								<h2>{strings.education}</h2>
							)}
							{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
								!this.props.ageGroup) && (
								<div>
									<label>
										{strings.municipality}
										<input
											onChange={this.handleInputChange}
											required
											type="text"
											name="municipality"
											id="municipality"
											placeholder={strings.municipality}
										/>
									</label>
									<label>
										{strings.county}
										<input
											onChange={this.handleInputChange}
											required
											type="text"
											name="county"
											id="county"
											placeholder={strings.county}
										/>
									</label>
								</div>
							)}
							{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
								!this.props.ageGroup) && (
								<div>
									<label>
										{strings.school}
										<input
											onChange={this.handleInputChange}
											required
											type="text"
											name="school"
											id="school"
											placeholder={strings.school}
										/>
									</label>
								</div>
							)}

							<div onChange={this.setEducation}>
								{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
									!this.props.ageGroup) && (
									<label className="checkbox-group-title">
										{strings.educationLevel}
									</label>
								)}

								{/* {(this.props.testKeyType === 'child' ||
									this.props.testKeyType ===
										'child-followup') && (
									<label>
										<input
											
											type="radio"
											name="education"
											value="barnehage"
										/>
										{strings.kindergarten}
									</label>
								)}
								{(this.props.testKeyType === 'child' ||
									this.props.testKeyType ===
										'child-followup') && (
									<label>
										<input
											required
											type="radio"
											name="education"
											value="barneskole"
										/>
										{strings.primarySchool}
									</label>
								)} */}
								{(['youth-followup','child-followup'].includes(this.props.testKeyType)) &&
									!this.props.ageGroup && (
										<span>
											<label>
												<input
													required
													type="radio"
													name="education"
													value="ungdomsskole"
												/>
												{strings.juniorHighSchool}
											</label>
											{/* <label>
											<input
												required
												type="radio"
												name="education"
												value="videregaendeskole"
											/>
											{strings.highSchool}
										</label>
										<label>
											<input
												required
												type="radio"
												name="education"
												value="universitet"
											/>
											{strings.university}
										</label>
										<label>
											<input
												required
												type="radio"
												name="education"
												value="other"
											/>
											{strings.other}
										</label> */}
										</span>
									)}
								{this.props.testKeyType ===
									'adult-followup' && (
									<span>
										<label>
											<input
												required
												type="radio"
												name="education"
												value="videregaendeskole"
											/>
											{strings.highSchool}
										</label>
										<label>
											<input
												required
												type="radio"
												name="education"
												value="universitet"
											/>
											{strings.university}
										</label>
										<label>
											<input
												required
												type="radio"
												name="education"
												value="other"
											/>
											{strings.other}
										</label>
									</span>
								)}
							</div>
							<br />
							{this.state.education === 'barnehage' && (
								<label>
									{strings.pick}
									<select
										required
										name="class"
										onChange={this.handleInputChange}
									>
										<option value="">
											{strings.pickYear}
										</option>
										<option value="0">
											{strings.lastYearBeforeSchool}
										</option>
										<option value="1">
											{strings.formatString(
												strings.classNum,
												1
											)}
										</option>
									</select>
								</label>
							)}
							{this.state.education === 'barneskole' && (
								<label>
									{strings.class}
									<select
										required
										name="class"
										onChange={this.handleInputChange}
									>
										<option value="">
											{strings.class}
										</option>
										{/* <option value="1">
											{strings.formatString(
												strings.classNum,
												1
											)}
										</option> */}
										<option value="2">
											{strings.formatString(
												strings.classNum,
												2
											)}
										</option>
										<option value="3">
											{strings.formatString(
												strings.classNum,
												3
											)}
										</option>
										<option value="4">
											{strings.formatString(
												strings.classNum,
												4
											)}
										</option>
										<option value="5">
											{strings.formatString(
												strings.classNum,
												5
											)}
										</option>
										<option value="6">
											{strings.formatString(
												strings.classNum,
												6
											)}
										</option>
										<option value="7">
											{strings.formatString(
												strings.classNum,
												7
											)}
										</option>
									</select>
								</label>
							)}
							{this.state.education === 'ungdomsskole' &&
								!this.props.ageGroup && (
									<label>
										{strings.class}
										<select
											required
											name="class"
											onChange={this.handleInputChange}
										>
											<option value="">
												{strings.pickClass}
											</option>
											<option value="8">
												{strings.formatString(
													strings.classNum,
													8
												)}
											</option>
											<option value="9">
												{strings.formatString(
													strings.classNum,
													9
												)}
											</option>
											<option value="10">
												{strings.formatString(
													strings.classNum,
													10
												)}
											</option>
										</select>
									</label>
								)}
							{this.state.education === 'videregaendeskole' && (
								<label>
									{strings.class}
									<select
										required
										name="class"
										onChange={this.handleInputChange}
									>
										<option value="">
											{strings.pickStudyType}
										</option>
										<option value="studiespesialisering">
											{strings.specialization}
										</option>
										<option value="yrkesfag">
											{strings.vocational}
										</option>
									</select>
								</label>
							)}
							{this.state.education === 'universitet' && (
								<label>
									{strings.class}
									<select
										required
										name="class"
										onChange={this.handleInputChange}
									>
										<option value="">
											{strings.pickLevel}
										</option>
										<option value="bachelor">
											{strings.bachelor}
										</option>
										<option value="master">
											{strings.master}
										</option>
										<option value="phd">
											{strings.phd}
										</option>
									</select>
								</label>
							)}
							{this.state.education === 'other' && (
								<label>
									{strings.specify}
									<input
										required
										type="text"
										name="educationOtherExplain"
										onChange={this.handleInputChange}
									/>
								</label>
							)}
						</Fragment>
						{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
							!this.props.ageGroup) && <h2>{strings.other}</h2>}

						{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
							!this.props.ageGroup) && (
							<label>
								{strings.bilinguagualHomeQuestion}
								<select
									name="languagesInHome"
									required
									onChange={this.handleInputChange}
								>
									<option value="">{strings.pick}</option>
									<option value="one">
										{strings.unilingual}
									</option>
									<option value="two">
										{strings.bilingual}
									</option>
									<option value="multiple">
										{strings.multilingual}
									</option>
								</select>
							</label>
						)}
						{(!['youth-followup','child-followup'].includes(this.props.testKeyType) ||
							!this.props.ageGroup) && (
							<label>
								<input
									type="checkbox"
									name="agreedToPrivacyPolicy"
									required
								/>{' '}
								{strings.iConsent}
								<br />
								{strings.consentText}{' '}
								<a
									target="_blank"
									rel="noopener noreferrer"
									href="https://literate.no/personvernerklaering/"
								>
									{strings.literatePrivacyPolicy}
								</a>
							</label>
						)}

						<AdminReadyButton
							remoteIsReady={isClientReady}
							// onClick={this.handleReadyClick}
						/>
					</form>
				</div>
			</div>
		);
	}
}

export default TestIntro;
